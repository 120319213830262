/* .sidebar {
    width: 250px;
    height: 100vh;
    background-color: #2F4050;
}

.sidebarList {
    height: auto;
    padding: 0; 
    margin: 0;
    width:100%;
}

.sidebarList .row{
    width:100%;
    height: 40px;
    border: 1px soid black;
    list-style-type: none;
    margin:0%;
    padding: 0 10px;
    display: flex;
    color:white;
    justify-content: center;
    align-items: center;   
}

.sidebarList a{
    width:100%;
    text-decoration: none;
    color:white;
    font-size: 14px;
}

.sidebarList #active {
    background-color: #293846;
}

.sidebarList .row:hover {
    cursor: pointer;
    background-color: #293846;
}

 */



  .dflex {
    display: flex;
  }
  

  .navbar-logo {
    width: 6rem;
  }

  .navbar-tabs {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-tabs a {
    text-decoration: none;
    font-size: 1rem;
    margin: auto 1rem;
    color: #ffffff;
  }
  
  .navbar-tabs > #activetab {
    background-color: #ffffff;
  }

  .navbar-tabs>#activetab a {
    color: #000000;
  }
   .menu-bars {
    margin-right: 2rem;
    /* font-size: 2rem; */
    background: none;
  } 
  .menu-bars-close {
    padding: 0px 10px
  }
  
  .nav-menu {
    background-color: #3d4e7a;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 10;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 1;
  }
  
  /* .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  } */
  
  .nav-menu-items {
    margin-top: 80px;
  }
  .nav-menu-items .row{
    width:100%;
    min-height: 50px;
    border: 1px soid black;
    list-style-type: none;
    /* margin:0%; */
    padding: 0 10px;
    display: flex;
    color:white;
    justify-content: center;
    align-items: center;   
}
  .nav-menu-items a{
    width:100%;
    text-decoration: none;
    color:white;
    font-size: 14px;
  }

  .nav-menu-items #active {
    background-color: #293846;
  }

  #activetab {
    background-color: #293846;
  }
  .nav-menu-items .row:hover {
      cursor: pointer;
      background-color: #293846;
  }

  /* .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  } */
  
  .navbar-toggle {
    background-color: #3d4e7a;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  

  