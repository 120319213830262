@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
/*------
Header 
--------*/
.header {
  background-color: #28676A;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 5rem;
}

.header a {
  color: black;
}

.rightside{
  justify-content: flex-end;
}

.appname {
  font-size: 2rem;
  font-weight: 500;
  vertical-align: middle;
}

.header span {
  margin: 0 1rem;
}

.header p {
  margin-bottom: 0;
  color: #ffffff;
}



h1 {
  /* font-size: 2rem !important; */
  color: #2c292b;
  /* margin-bottom: 1.5rem !important; */
}

h2 {
  font-size: 1rem;
  color: #2c292b;
}

.mb-10, .mt-10, .row {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.error{
  color: red;
  font-size: 12px;
  margin-bottom: 0;
}

.required {
  color: #B8372B;
}

.form {
  padding: 1rem;
}
/* .form-check-inline {
  margin-left: 1.5rem;
} */
.form-check-inline input{
  margin-top: 0.5rem !important;
}
#typeOfDebtForm .form-check-label {
  margin-top: 0 !important;
}

.check-box > label {
  margin-top: 0 !important;
}

.control {
  margin-bottom: 0.5rem;
}

.control input,
.control textarea, .custom-input {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: .375rem .75rem;;
  width: 100%;
}

.btn-div {
  overflow: auto;
  margin-top: 1rem;
  border-top: 1px solid darkgray;
  padding-top: 10px;
}
.fright {
  float: right;
}
.fleft {
  float: left;
}
.button {
  cursor: pointer;
}

.binocular-icon {
  float: right;
  margin-top: 10px;
  cursor: pointer;
}

.login {
  padding: 60px 0;
}
.login form {
  margin:0 auto;
  max-width: 350px;
  border: 1px solid gray;
  padding: 15px;
 
}

.widgetIcon, .custom-icon {
  cursor: pointer;
}
.stepwrapper {
  display: flex;
  margin: 20px 20px;
  position: sticky;
}
.stepBlock {
  flex: 1 1 0;
  position: relative;
}

.stepBlock span {
  font-size: 12px;
}
/* .selected .circle {
  color: black;
  background-color: white;
  border-color: green;
} */

.stepBlock:not(:last-child) .circleWrapper::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: -40px;
  bottom: 0px;
  background-color: #d4d8d8;
  margin: auto;
  z-index: -1;
}
.stepBlock:not(:last-child) > .circleWrapper::after  {
  height: 2px 
}

.stepBlock.selected .widgetIcon {
    border: 2px solid green;
    border-radius: 50%;
    /* width: 40px;
    height: 40px; */
    position: relative;
}

.printBtn {
  float: right;
  width: 2em;
  height: 2em;
  cursor: pointer;
}
/* ------------------------------------------
                 PANEL
--------------------------------------------- */
.panel {
  background-color: #fff;
}
.panel-heading{
  background-color: #dcdcdc;
  width: 100%;
  border-bottom: 1px solid transparent;
}  
.panel-footer {
  padding: 10px 15px;
  background-color: #eaebec;
  border-top: 1px solid #d4d8d8;
}

.panel-default>.panel-heading {
  color: #000;
  background-color: #eaebec;
  border-color: #d4d8d8;
}

.panel h1.menu-title, .panel h1.main-title {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0 0 10px 0;
  padding-top: .5rem;
}
/* ------------------------------------------
                 BOX BACKGROUNDS
--------------------------------------------- */

.sub-header {
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: #28676A;
}

  /* ------------------------------------------
      BUTTONs
  --------------------------------------------- */

  .custom-button {
    background-color: #3d4e7a;
    color: #fff;
    font-weight: bold;
    padding: .5rem 1rem;
    border-radius: .5rem;
    border: none;
    margin-bottom: 1rem;
    font-size: 18px;
    }
  .custom-button:hover {
    background-color: #00918d;
    }
  .custom-button:disabled,
    .custom-button[disabled]{
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  
  .custom-button-secondary {
    background-color: gray;
    font-weight: bold;
    padding: .5rem 1rem;
    border-radius: .5rem;
    border: 1px solid transparent;
    margin-bottom: 1rem;
  }

  .dropdown-menu[data-bs-popper] {
      right: 0 !important;
      left: auto !important;
  }
/*-----------------------
        Container
-----------------------*/

  .custom-container {
    margin: 0.5rem auto;
  }
  

  .icon-style {
    padding: 4px;
    cursor: pointer;
  }

  .help-icon {
    cursor: pointer;
  }
  .heading-help-icon {
    width:1rem;
  }

  span label {
    margin: 0;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }
  .center-align {
    text-align: center
  }
  .dk-blue-color {
    color: #3d4e7a;
    text-decoration: none;
    cursor: pointer;
  }
  .dk-blue-color:hover {
    color: #00918d;
  }

  table {
    width: 100%;
  }
  .admin-widget li a {
    text-decoration: none;
  }

  .date-picker-icon {
    float: right;
    margin-right: 6px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
}

.modal-dialog {
  max-width: 825px !important;
}

.formCheckBoxLabel {
  margin-left: 10px;
}

.widget {
  margin: 10px 5px;
}
.widget table td {
  border-bottom: 0px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc !important;
}
.pagination {
  float: right;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #0d6efd;
}

.mkr-context p, .mkr-context ol{
  font-size: 0.785rem;
  line-height: 1.25rem;
}

.field-label {
  font-weight: bold;
}

dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0
}
dt {
  float: left;
  width: 50%;
  font-weight: 500;
  overflow: auto;
  word-break: break-all;
  padding: 0;
  margin: 0
}
dd {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0
}
.docrequirements {
  font-size: 12px;
  color: green;
  list-style: none;
  padding-left: 0px;
}

.bd-example-modal-lg .modal-dialog{
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
}

.bd-example-modal-lg .modal-dialog .modal-content{
  background-color: transparent;
  border: none;
}
.tblCol>.custom-button {
  margin-bottom: 0;
}

.tblCol>.custom-button:disabled,
.tblCol>.custom-button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

@media screen and (max-width: 500px) {
  .button-text {
      font-size: 10px;
  }
}

.pdf-box {
  background-color: #fff;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.authModal .modal-dialog, .adtrSummaryModal .modal-dialog {
  max-width: 890px !important;
}

.view-issuances .modal-dialog {
  max-width: 1140px !important;
}

/* Loader */
.loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
/*---------------------------
      PAGINATION
-----------------------------*/
.p-paginator .p-paginator-current {
  flex: 1 1 auto;
  text-align: left;
  justify-content: left;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: hsl(208 7% 42% / 1);
}

.p-datatable .p-paginator .p-link .p-icon {
  padding: 0 !important;
}

.react-datepicker-wrapper {
  display: block !important;
}